import React, { useEffect, useState } from 'react';
import { reduxForm } from 'redux-form'
import { userService } from '../_services';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { useTranslation } from "react-i18next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import Modal from 'react-modal';
import moment from 'moment';



const customStyles1 = {
    overlay: {
        display: "block",
        paddingRight: "15px",
        backgroundColor: 'rgba(51,51,51,0.8)',
        zIndex: 99
    },

    content: {
        position: "",
        top: "",
        right: "",
        bottom: "",
        left: ""
    }
};


const ReportPage = (props: any) => {
    const { t, i18n } = useTranslation();
    const [isLoading, setisLoading] = useState(false);
    const [getAllJobs, setGetAllJobs] = useState([])
    const [getAllUser, setGetAllUser] = useState([])
    const [jobReportedModal, setJobReportedModal] = useState(false)
    const [jobReportedUser, setJobReportedUser] = useState([]) as any;

    useEffect(() => {
        getReportedJob()
        getReportedUser()
    }, [])
    const getReportedUser = () => {
        setisLoading(true)
        userService.geReportedUserist()
            .then(response => {
                setGetAllUser(response.data.data)
                setisLoading(false)
            })
            .catch(error => {
                setisLoading(false)
                console.log(error.response)
            });

        setisLoading(false)

    }

    const onGetjobReportedUser = (id) => {
        setisLoading(true)
        console.log("id", id)
        userService.jobReportUsers(id)
            .then(response => {
                setJobReportedUser(response.data.data)
                setisLoading(false)
                setJobReportedModal(true)
            })
            .catch(error => {
                setisLoading(false)
                console.log(error.response)
            });

        setisLoading(false)
    }
    const onGetUserReportedUser = (id) => {
        setisLoading(true)
        console.log("id", id)
        userService.userReportUsers(id)
            .then(response => {
                setJobReportedUser(response.data.data)
                setisLoading(false)
                setJobReportedModal(true)
            })
            .catch(error => {
                setisLoading(false)
                console.log(error.response)
            });

        setisLoading(false)
    }
    const getReportedJob = () => {
        setisLoading(true)
        userService.geReportedJobList()
            .then(response => {
                setGetAllJobs(response.data.data)
                setisLoading(false)

            })
            .catch(error => {
                setisLoading(false)

                console.log(error.response)
            });

        setisLoading(false)

    }

    const columns = [{
        dataField: 'Job Title',
        text: t('Job Title'),
        formatter: (cell, row) => {

            return (
                <>
                    <a >{row.job_id.title}</a>

                </>
            );
        }
    },
    {
        dataField: 'Total Report',
        text: t('Total Report'),
        formatter: (cell, row) => {

            return (
                <>

                    <a className="view_jobs" onClick={() => onGetjobReportedUser(row?.job_id?._id)}  >{row.total_report_counts}</a>

                </>
            );
        }
    },
    {
        dataField: 'Job Status',
        text: t('Job Status'),
        formatter: (cell, row) => {

            return (
                <>
                    <a >{row.job_id.job_status}</a>

                </>
            );
        }
    }, {
        dataField: 'Budget',
        text: t('Budget'),
        formatter: (cell, row) => {

            return (
                <>
                    <a >{row.job_id.budget}</a>

                </>
            );
        }
    },
    {
        dataField: 'Action',
        text: t('Action'),
        formatter: (cell, row) => {

            return (
                <>
                    {row.job_id?.job_status == 'Enable' ?
                        <button onClick={() => onChangeJobStatus(row.job_id?._id, 'Disable')} className="suspend_button">{t("Disable")}</button>
                        :
                        <button onClick={() => onChangeJobStatus(row.job_id?._id, 'Enable')} className="active_button">{t("Enable")}</button>}

                </>
            );
        }
    },

    {
        dataField: 'view',
        text: t('View'),
        formatter: viewFormatter
    }
    ];

    const columnsUser = [{
        dataField: 'Reported User',
        text: t('Reported User'),
        formatter: (cell, row) => {

            return (
                <>
                    <a >{row.reported_user.name}</a>

                </>
            );
        }
    },
    {
        dataField: 'Email',
        text: t('Email'),
        formatter: (cell, row) => {

            return (
                <>
                    <a >{row.reported_user.email}</a>

                </>
            );
        }
    }, {
        dataField: 'Total Report',
        text: t('Total Report'),
        formatter: (cell, row) => {

            return (
                <>
                    <a className="view_jobs" onClick={() => onGetUserReportedUser(row.reported_user._id)} >{row.total_report_counts}</a>

                </>
            );
        }
    }, {
        dataField: 'job Completed',
        text: t('job Completed'),
        formatter: (cell, row) => {

            return (
                <>
                    <a >{row.reported_user.jobCompleted}</a>

                </>
            );
        }
    },

    {
        dataField: 'action',
        text: t('Action'),
        sort: true,
        formatter: actionFormatter,
        sortValue: (cell, row) => row.status
    },
    {
        dataField: 'view',
        text: t('View'),
        formatter: viewFormatterw
    }
    ];
    function viewFormatterw(cellContent, row, rowIndex) {
        return (
            <>
                <a onClick={() => onViewUser(row)} className="action_btn"><i className="fa fa-eye" aria-hidden="true"></i></a>

            </>
        );
    }
    function viewFormatter(cellContent, row, rowIndex) {
        return (
            <>
                <a onClick={() => onView(row)} className="action_btn"><i className="fa fa-eye" aria-hidden="true"></i></a>

            </>
        );
    }

    function actionFormatter(cellContent, row, rowIndex) {
        return (
            <>
                {row.reported_user.status == 'ACT' ?
                    <button onClick={() => suspendUser(row.reported_user._id, 'SUS')} className="suspend_button">{t("Suspend")}</button>
                    :
                    <button onClick={() => suspendUser(row.reported_user._id, 'ACT')} className="active_button">{t("Activate")}</button>
                }
            </>
        );
    }
    const onView = (row) => {
        window.location.href = '/jobdetail/' + row.job_id?._id
    }


    const onViewUser = (item) => {
        window.location.href = '/userprofile/P/' + item.reported_user?._id;
    }


    function suspendUser(id, status) {
        let result;
        if (status == "SUS") {
            result = window.confirm(t("Are you sure you want to suspend this worker's account?"))
        }
        else {
            result = window.confirm(t("Are you sure you want to activate this worker's account?"))
        }

        if (result) {
            setisLoading(true)
            var params = {
                user_id: id,
                status: status
            }
            userService.changeStatus(params)
                .then(response => {
                    console.log("response.user", response)
                    setisLoading(false)
                    setTimeout(
                        () => {
                            toast.success(status == "SUS" ? t("Suspended successfully") : t("Activated Successfully"))
                        },
                        500
                    );
                    getReportedUser();

                })
                .catch(error => {
                    console.log(error.response)
                    setisLoading(false)

                });
        }
    }



    const onChangeJobStatus = (id, status) => {
        let params = {
            job_status: status,
            id: id
        }
        setisLoading(true)

        userService.enableDisableJob(params)
            .then(response => {
                console.log(response)
                setisLoading(false)
                alert(response.data.message)
                getReportedJob()
            })
            .catch(error => {
                console.log(error.response)
                setisLoading(false)
            });
    }


    return (
        <section className="my_job_tab_box">
            {isLoading &&
                <div className="loader-back">
                    <div className="loaderrrr">
                        <span>
                            <img src={require("../assets/images/loader.gif")} alt="" />
                        </span>

                    </div>
                </div>
            }
            <div className="tab_heading_content">
                <ul className="nav nav-tabs">
                    <li className="active active_list"><a data-toggle="tab" href="#job">{t("Jobs")}</a></li>
                    <li className="past-list"><a data-toggle="tab" href="#providers">{t("Providers")}</a></li>

                </ul>
            </div>
            <div className="tab-content">
                <div id="job" className="tab-pane fade in active">
                    <div className="tables_area">
                        <h2 className="pull-left">{t("Jobs")}</h2>

                        <div className="clear"></div>
                        <div className="white_box">
                            <div className="table-responsive">
                                <div className="pagination_box">
                                    <BootstrapTable keyField='id' data={getAllJobs} columns={columns} pagination={paginationFactory({ sizePerPage: 5 })} />
                                </div>

                            </div>

                        </div>
                    </div>
                </div>





                <div id="providers" className="tab-pane fade">
                    <div className="tables_area">
                        <h2 className="pull-left">{t("Providers")}</h2>
                        <div className="clear"></div>
                        <div className="white_box">
                            <div className="table-responsive">
                                <div className="pagination_box">
                                    <BootstrapTable keyField='id' data={getAllUser} columns={columnsUser} pagination={paginationFactory({ sizePerPage: 5 })} />
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

                <Modal
                    isOpen={jobReportedModal}
                    ariaHideApp={false}

                    className={
                        "modal-content  new_modal_content add_profile_modal-content category-modal"}
                    contentLabel="Example Modal"
                    style={customStyles1}
                >
                    <div className="modal-header">
                        <h4 className="modal-title">{t("Reported by")}<span><i className="fa fa-times-circle-o" onClick={() => setJobReportedModal(false)} aria-hidden="true" data-dismiss="modal"></i></span> </h4>
                    </div>
                    <div className="modal-body guest-user-popup">

                        <ul>
                            {jobReportedUser.map((user, index) => (
                                <li key={index}>

                                    <div className='block-user-left'>
                                        <div className='block-user-image'>
                                            <img
                                                src={user.reported_by?.profile_image} alt='img' />
                                        </div>
                                        <div className='block-user-content'>
                                            <div className='block-user-name-reported-date'>
                                                <h6>{user.reported_by?.name}</h6>
                                                <p>{moment(user.report_reason_id.createdAt).format('D MMM YYYY, h:mmA')}</p>
                                            </div>
                                            <div className='reson-area'>
                                                <span><b>Reason:</b> {user?.report_reason_id?.type !== "Other" ? user?.report_reason_id?.report_reason : user?.report_reason}</span>
                                            </div>

                                        </div>

                                    </div>


                                </li>
                            ))}
                        </ul>

                    </div>
                </Modal>

            </div>
            <ToastContainer />

        </section>

    )
}

export default reduxForm({
    form: 'loginForm',// a unique identifier for this form
})(ReportPage)